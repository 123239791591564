import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import Gallery from 'components/gallery';
import IOExample from 'components/io-example';
import Modal from 'containers/modal';
import { graphql } from 'gatsby';
import Menu from 'components/menu';
import Img from 'gatsby-image';
import Head from 'components/head';

const Index = ({ data }) => (
   <div id="root">
   <Layout>
        <Head pageTitle={data.homeJson.title} />
        <Gallery items={data.homeJson.gallery} />

<Box>
        <div className="wrapper">
        <div className="contact">

        {data.contactJson.gallery.map((item, i) => (
                          <a href={item.copy} key={i}>
                          <Img
                          style={{opacity: 0.8,
                                  borderRadius: '80%',
                                  filter: 'grayscale(10%)',
                                  border: '4px solid #C0C0C0',
                                  margin: '1rem'
                          }}
                          fixed={item.image ? item.image.childImageSharp.fixed : {}}
                          alt={item.title} key={i}
                          /></a>
                      ))}

        </div>
        </div>
    </Box>
   </Layout></div>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        copy2
        image {
          childImageSharp {
            fluid(maxHeight: 260, maxWidth:800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    },
    iconsJson {
          title
          content {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          gallery {
            title
            copy
            image {
              childImageSharp {
                fixed(height: 40) {
                  ...GatsbyImageSharpFixed

                }
              }
            }
          }
        }
        contactJson {
                title
                content {
                  childMarkdownRemark {
                    html
                  }
               }
              gallery {
                            title
                            copy
                            image {
                              childImageSharp {
                                fixed(height: 40, quality: 90) {
                                  ...GatsbyImageSharpFixed

                                }
                              }
                            }
                          }
              }
  }
`;
