import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { bool } from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import { Container } from './header.css';
import Title from 'components/title';
import Nav from 'components/header/nav';
//import VisibilitySensor from "react-visibility-sensor";
import logo from "../../images/logoadasol3.ico";
import Burger from 'components/header/burger';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});


const Header=({title, open, setOpen}) => {
    //const [open, setOpen] = useState(false);
  const [scrolled,setScrolled]=React.useState(false);
    const handleScroll=() => {
        const offset=window.scrollY;
        if(offset > 100 ){
          setScrolled(true);
        }
        else{
          setScrolled(false);
        }
      }
      useEffect(() => {
        window.addEventListener('scroll',handleScroll)
      })
    let headerClasses=['header'];
      if(scrolled){
        headerClasses.push('scrolled');
      }


    return(

        <Container scrolled={scrolled}>

          <div className={headerClasses.join(" ")}>
              <div className="logo">
               <Link to="/">
               adasol.hr | Edge to Cloud Solutions.
               </Link>
              </div>
        <div className="burger">
            <Burger open={open} setOpen={setOpen}  />
        </div>
        </div>

        </Container>

    );

}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  scrolled: PropTypes.bool,

};

export default Header;
/*
// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

//const [open, setOpen] = useState(false);

const Header = ({ title }) => (
  <AnimatedContainer>
    <Container>
      <Link to="/" className="logoType">
          <div  className="logo">
           adasol.hr | Edge to Cloud Solutions.
          </div>
    </Link>
    <Burger open={0} />
    </Container>
  </AnimatedContainer>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
*/

