import React, {useState, useEffect} from 'react';
import {bool} from 'prop-types';
import { StyledMenu } from './menu.css';
import { Link } from 'gatsby';
import posed from 'react-pose';

const AnimatedContainer = posed.div({
  enter: {
    x: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    x: '-150%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Menu = ({open}) => (
    <AnimatedContainer>
    <StyledMenu open={open}>
        <Link to="/">Home</Link>
        <Link to="/offering">Services</Link>
        <Link to="/technologies">Technology Stack</Link>
        <Link to="/references">Projects</Link>
        <Link to="/contact">Contact</Link>
    </StyledMenu>
    </AnimatedContainer>
);

Menu.propTypes={
    open: bool.isRequired,
}

export default Menu;