import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import Gallery from 'components/gallery_s';
import Stack from 'components/stack';
import IOExample from 'components/io-example';
import Modal from 'containers/modal';
import { graphql } from 'gatsby';
import Menu from 'components/menu';
import Img from 'gatsby-image';
import Head from 'components/head';

const Technologies = ({ data }) => (
   <Layout>

        <Head pageTitle={data.technologiesJson.title} />
        <Box>
         <div className="title">
            Technology stack
        </div>
        </Box>
        <Stack items={data.technologiesJson.gallery} items2={data.technologiesJson.gallery2} items3={data.technologiesJson.gallery3} items4={data.technologiesJson.gallery4} />

        <Box>

        {data.technologiesJson.gallery4.map((item, i) => (
                           <Img
                           fixed={item.image ? item.image.childImageSharp.fixed : {}}
                           alt={item.title}
                           key={i}
                           style={{opacity: 1, filter: 'grayscale(0%)',margin: '0rem 2rem'}}
                           />

                ))}

        </Box>
        <Box>
                       <div className="wrapper">
                       <div className="contact">
                       {data.contactJson.gallery.map((item, i) => (
                                         <a href={item.copy} key={i}>
                                         <Img
                                         style={{opacity: 0.8,
                                                 borderRadius: '80%',
                                                 filter: 'grayscale(10%)',
                                                 border: '4px solid #C0C0C0',
                                                 margin: '1rem'
                                         }}
                                         fixed={item.image ? item.image.childImageSharp.fixed : {}}
                                         alt={item.title} key={i}
                                         /></a>
                                     ))}
                       </div>
                       </div>
                   </Box>
    </Layout>

);

Technologies.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Technologies;

export const query = graphql`
  query TechnologiesQuery {
    technologiesJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fixed(width: 48) {
              ...GatsbyImageSharpFixed

            }
          }
        }
      }
    gallery2 {
            title
            copy
            image {
              childImageSharp {
                fixed(width:48) {
                  ...GatsbyImageSharpFixed

                }
              }
            }
          }
          gallery3 {
                  title
                  copy
                  image {
                    childImageSharp {
                      fixed(width:48) {
                        ...GatsbyImageSharpFixed

                      }
                    }
                  }
                }
                gallery4 {
                                  title
                                  copy
                                  image {
                                    childImageSharp {
                                      fixed(width:60, quality:50) {
                                        ...GatsbyImageSharpFixed

                                      }
                                    }
                                  }
                                }
    }

    contactJson {
        title
        content {
          childMarkdownRemark {
            html
          }
       }
      gallery {
                    title
                    copy
                    image {
                      childImageSharp {
                        fixed(height: 40, quality: 90) {
                          ...GatsbyImageSharpFixed

                        }
                      }
                    }
                  }
      }
    }
`;



