import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.header`
  display: block;
  //grid-template-columns: repeat(2, 2fr);
  grid-gap: 0 0rem;
  padding: 0 0rem;
  margin: 0rem;
  //align-items: center ;
  //text-align: justify;
  justify-content: right;

  //display: flex;

  //justify-content: space-between;
  //justify-content: center;
  //align-items: left;
  //padding: 0rem;
  //margin: 0px;

  //font-size:1.3rem;



`;
