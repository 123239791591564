import styled from 'styled-components';
import { accent } from 'constants/theme';

export const Container = styled.div`

  padding: 0rem;
  margin-bottom: 0rem;
  border-radius: 0%;
  position: relative;
  text-align: center;
  font-size:1.5rem;
  //color: #FFFFFF;
  color: #C00000 ;
  //opacity: 0.2;
  transition: background-color 0.3s ease;

  img{
    padding: 0 0rem;

  }
  background-color: ${({ isVisible }) => (isVisible ? '#C0C0C0' : ' #C00000')};
//background-color: #C0C0C0;
 // &:hover {
  //background-color: #C00000;

    //}
  //background-color:#C00000;
  opacity:0.6;
`;
