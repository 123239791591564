import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
    display: grid;
  text-align: center;

    grid-template-columns: repeat(4, 2fr);
    grid-gap: 3rem 3rem;
    padding: 3rem 3rem;
    margin: 1rem 3rem;
    align-items: center ;


a {
    //color: #C00000 ;

    font-size: 2.0rem;
    transition: color 0.5s ease;
    text-decoration: none;
    filter: opacity(30%);
&:hover{
    filter: opacity(100%);
    color:inherit;
    transform: rotate (100deg);
    }
}



  ${MEDIA.TABLET`
    display: block;
  `};

`;

