import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Title, Copy, Copy2, Wrapper } from './item.css';
import { Link } from 'gatsby';
import Collapsible from 'react-collapsible';

const Item = ({ title, copy, copy2, image }) => (
    <Wrapper>
    <figure>
    <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
    <figcaption>
     <Title>{title}</Title>

         <Collapsible
                    className="item"
                    openedClassName="item active"
                    triggerClassName="item-title"
                    triggerOpenedClassName="item-title active"
                    triggerTagName="string"
                    contentInnerClassName="item-content"
                      trigger={copy}
                      transitionTime={300}
                      easing="ease-out"
                    >
        <Copy2>{copy2}</Copy2>
        </Collapsible>
    </figcaption>

  </figure>
</Wrapper>
);

Item.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object.isRequired,
};

export default Item;

