import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-gap: 0 4rem;
  padding: 0 0rem;
  margin: 0rem 2rem;
  align-items: center ;


  ${MEDIA.TABLET`
    display: block;

  `};

img{

    filter: grayscale(80%);

    &:hover{
    filter: grayscale(0%);
    }

};

a {
    //color: #C00000 ;
    font-size: 2.0rem;
    transition: color 0.5s ease;
    text-decoration: none;

    &:hover {
      color: inherit;

    }

};
`;
