import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Title, Copy, Wrapper } from './item_s.css';
import { Link } from 'gatsby';
import Collapsible from 'react-collapsible';

const Item_s = ({ title, copy, image }) => (
<Wrapper>
<figure>

    <Img fixed={image ? image.childImageSharp.fixed : {}} alt={title} />
    <figcaption>
        <Collapsible
            className="item"
            openedClassName="item active"
            triggerClassName="item-title"
            triggerOpenedClassName="item-title active"
            triggerTagName="string"
            contentInnerClassName="item-content"
              trigger={title}
              transitionTime={300}
              easing="ease-out"
            >
            <Copy>{copy}</Copy>
        </Collapsible>

    </figcaption>



</figure>
</Wrapper>
);

Item_s.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object.isRequired,
};

export default Item_s;
