import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import Img from 'gatsby-image';
import Gallery from 'components/gallery_s';


const Contact = ({ data }) => (

  <Layout>
    <Head pageTitle={data.contactJson.title} />
    <Box>
    <div className="title">
    Contact
    </div>
    </Box>
    <Box>
        <div className="centered">
        {data.contactJson.gallery.map((item, i) => (
                          <a href={item.copy} key={i}>
                          <Img
                          style={{opacity: 0.8,
                                  borderRadius: '80%',
                                  filter: 'grayscale(10%)',
                                  border: '4px solid #C0C0C0',
                                  margin: '1rem'


                          }}
                          fixed={item.image ? item.image.childImageSharp.fixed : {}}
                          alt={item.title} key={i}
                          /></a>

                      ))}
           </div>
    </Box>


  </Layout>
);

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    contactJson {
      title
      content {
        childMarkdownRemark {
          html
        }
     }
    gallery {
                  title
                  copy
                  image {
                    childImageSharp {
                      fixed(height: 140, quality: 90) {
                        ...GatsbyImageSharpFixed

                      }
                    }
                  }
                }
    }
  }
`;

