import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { bool } from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
import Header from 'components/header';
import GlobalStyle from 'global.css.js';
import Menu from 'components/menu';
import FocusLock from 'react-focus-lock'
import { Container } from './layout.css';


function Layout ({ data, children }){
   const [open, setOpen] = useState(false);
    return(
    <Container>
        <GlobalStyle />
        <Head />
        <Header title={data.site.siteMetadata.siteTitle} open={open} setOpen={setOpen}  />
        {children}
        <Menu open={open} />
    </Container>
    );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,

};

export default LayoutWithQuery;
