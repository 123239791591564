import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { StyledBurger } from './burger.css';


const Burger = ({ open, setOpen }) => {

    //const [open, setOpen] = useState(false);
    return(

   <StyledBurger open={open} onClick={() => setOpen(!open)} >
      <div />
      <div />
      <div />
   </StyledBurger>

);

}
Burger.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,

};

export default Burger;

