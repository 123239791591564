import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.header`

.header{
    display: grid;
      grid-template: 2rem / auto auto;
      grid-gap: 0 1rem;
      padding: 0 1rem;
      margin: 2rem 2rem;
      align-items: center ;
      justify-content: right;
      //background-color: #FFF;
      transition: all .6s ease-in-out;
      //width:100%;
        //height:5vh;
}

.scrolled{
  display: grid;
        grid-template: 2rem / auto auto;
        grid-gap: 0 1rem;
        padding: 0 1rem;
        margin: 0rem 0;
        align-items: center ;
        display: flex;
  //place-content:space-around;
        place-content: center;

  width:100%;
  height:5vh;
  //text-align:center;

  position: fixed;
  top: 0;
  left:0;

  z-index: 1;
  background-color: #C00000;
  opacity:0.7;

}
  //display: flex;

  //justify-content: space-between;
  //justify-content: center;
  //align-items: left;
  //padding: 0rem;
  //margin: 0px;

  font-size:1.3rem;
  ${MEDIA.TABLET`
      //display: block;
    `};
  a {
    //color: #C00000 ;
    font-size: 1.2rem;
    transition: color 0.5s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }

.burger{
    //visibility: ${({ scrolled }) => (scrolled ? 'visible' : 'hidden')};
    visibility: ${({ scrolled }) => scrolled ? 'hidden' : 'visible'};


}

`;
