import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Title = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 1rem 1rem;
  color: #FFFFFF;
  //color: #C00000;
  //opacity:0.5;

`;

export const Copy = styled.p`
  //color: #757575;
  //color: #FFFFFF;
  margin: 0 1rem 2rem;
  font-size: 1.7rem;
`;

export const Copy2 = styled.p`
  //color: #757575;
  color: #C0C0C0;
  //color: #C00000;
  //filter: opacity(30%);
  //padding:0;
  margin: 1 0rem 1rem;
  font-size: 1.5rem;
  visibility: visible;

  //text-align: justify;
  //text-justify: inter-word;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
    font-size: 1.0rem;
  `};
`;

export const Wrapper = styled.div`
 object-fit: cover;


filter: opacity(30%);

  &:hover {
  filter: opacity(100%);


  }
  .item {
    //border-bottom: 2px solid #FFFFFF;
     margin: 0 1rem 2rem;
  }
  .item-title {
    border: none;
    background: none;
    padding: 0;
    outline: none;
    width: 100%;
    text-align: center;
    cursor: pointer;
    color: #FFFFFF;

    padding: 0rem;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-left: 2px solid #FFF;
      border-bottom: 2px solid #FFF;
      position: absolute;
      top: 14px;
      right: -20px;
      transform: rotate(-45deg);

      transition: transform 0.3s ease-in-out;


    }
    &.active {
      &::after {
        transform: rotate(135deg);
      }
    }
  }
  .item-content {
    margin: 1.5rem 0rem;
    //padding: 0;
    //padding: 0 12px 12px 1px;
    //line-height: 10px;
    //color: #C00000;
  }
`;