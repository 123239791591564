export { default } from './menu';

// Menu.styled.js
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //background: ${({ theme }) => theme.primaryLight};
  //background: #282c34;
  background: #C00000;
  //opacity:0.9   ;
  width: 28rem;
  height: 30rem;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 4rem;
  right: 3rem;
  transition: transform 0.6s ease-in-out;
  //transform: translateX(-100%);
  opacity: ${({ open }) => open ? '0.9':'0'};
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-1000%)'};


  ${MEDIA.TABLET`
    width: 100%;
    height:100%
    right: 0rem;
  `};

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    //color: ${({ theme }) => theme.primaryDark};
    color: #FFF;

    text-decoration: none;
    transition: color 0.3s linear;

    ${MEDIA.TABLET`
        font-size: 1.5rem;
        text-align: center;
      `};

    &:hover {
         //color: inherit;
         color: #282c34;

       }
  }
`;