import React from 'react';
import PropTypes from 'prop-types';
import Item_s from 'components/gallery_s/item_s';
import { Container } from './stack.css';
import Img from 'gatsby-image';
import Box from 'components/box';
const Stack = ({ items, items2, items3, items4 }) => (

    <Container>


      {items.map((item, i) => (
      <Item_s {...item} key={i} />
    ))}


          {items2.map((item, i) => (
          <Item_s {...item} key={i} />
        ))}



              {items3.map((item, i) => (
              <Item_s {...item} key={i} />
            ))}





   </Container>

);

Stack.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Stack;

// {items4.map((item, i) => (
      //             <Img fixed={item.image ? item.image.childImageSharp.fixed : {}} alt={item.title} key={i} />

        // ))}