import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  //place-content:space-around;
  place-content: center;
  //margin-left:100rem;
  padding: 2rem 2rem;
  max-width: 100%;
  max-height:100%;
  align-content: center ;
  text-align: center;
  color: #FFF;
  font-size: 3rem;

  //background-color: #FFF;
  //border-bottom:3px solid #FFF;



img{

filter: opacity(30%);
&:hover {
  filter: opacity(100%);
  }

}

.portrait{
    img{
    filter: opacity(100%);

    }
}

.centered{
    position: fixed;
    top:40%;
    //left:50%
}

.wrapper{
 width:100%;
//line-height: 2vh;
//line-height:100px;
//margin:0px;
//background-color: #FFF;


}
.contact{
    width:100%;

    //display:inline-block;
    //vertical-align: bottom;
    line-height:initial;
    padding-top:1rem;
    border-top:3px solid #C0C0C0;


}
  .about {
    font-size: 2rem;
    font-style: italic;
    text-indent: 2rem;
    line-height: 2.0;

    text-align: justify;
    max-width:100%;
    margin: 4rem 4rem;

  }
  .title {
      font-size: 2rem;
      font-style: bold;
      background-color:#C00000;
      text-align: center;
      max-width:100%;
      margin: 0rem 0rem;
      padding: 0.5rem 1rem;
      opacity: 0.5;
      width:100%;
    }
    .title2 {
          font-size: 2rem;
          font-style: bold;
          background-color:#C00000;
          text-align: center;
          max-width:100%;
          margin: 0rem 1rem;
          padding: 1rem 10rem 1rem 44rem;
          opacity: 0.6;
        }
.placeHolder{
    line-height:50vh;
    background-color: #FFF;

}

.customers{
    background-color: #FFF;
    filter: opacity(30%);
    &:hover {
      filter: opacity(100%);
      }
    width:100%;
    img{
            filter: opacity(100%);
        }
}
`;
