import React from 'react';
import PropTypes from 'prop-types';
import Item_s from 'components/gallery_s/item_s';
import { Container } from './gallery_s.css';

const Gallery_s = ({ items }) => (

    <Container>

    <div>
      {items.map((item, i) => (
      <Item_s {...item} key={i} />
    ))}
    </div>
   </Container>

);

Gallery_s.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Gallery_s;
