import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import IOExample from 'components/io-example';
import Img from 'gatsby-image';

const About = ({ data }) => (
  <Layout>
 <Head pageTitle={data.aboutJson.title} />

    <Box>
<div className="title">
            About
            </div>
    </Box>

<Box>

    <div className="portrait">

       {data.aboutJson.gallery.map((item, i) => (
                  <Img
                  style={{opacity: 1.0,
                          borderRadius: '100%',
                          filter: 'grayscale(80%)'

                  }}
                  fixed={item.image ? item.image.childImageSharp.fixed : {}}
                  alt={item.title} key={i}
                  />
              ))}

<h1>Damir Srdanovic</h1>
       <div className="about">

       Hello and welcome to my humble website! Here you can find some basic info about me and what I can offer. If you would like to know more, let&#39;s connect and talk!
       <p/>
       I am specialized in industrial automation and power generation solutions and services.
       After almost 15 great years working for a wonderful company such as ABB, I have gained solid KNOWLEDGE and EXPERIENCE in control systems for industrial environments. And this is what I continue to build on.
       I am offering enterprise knowledge and experience delivered with agility and dedication! I help my customers to improve (or just fix) their edge technologies (on-premise automation) and integrate it to the cloud and create additional value from it.
       <p/>
       My temporary home for the next year is the nice city of Omaha (/ˈōməhä/), Nebraska, USA, but with mobility I continue to support my customers globally, having my offices registered in my beautiful country Croatia and my second (European) home, breathtaking Norway.
       <p/>
       My background and the skills I have developed over the years (and still keep up with the continuous learning!) give me the opportunity to deal with the implementation and servicing of different IT/OT components in the technology stack, from edge to cloud.
       Furthermore, I provide engineering to commissioning services but also give support for sales and project management.
       <p/>
       If you have a task to be done, problem to be solved or you would just like to have a chat, don&#39;t hesitate to reach out!


              </div>
      </div>

    </Box>
   <Box>
                  <div className="wrapper">
                  <div className="contact">
                  {data.contactJson.gallery.map((item, i) => (
                                    <a href={item.copy} key={i}>
                                    <Img
                                    style={{opacity: 0.8,
                                            borderRadius: '80%',
                                            filter: 'grayscale(10%)',
                                            border: '4px solid #C0C0C0',
                                             margin: '1rem'
                                    }}
                                    fixed={item.image ? item.image.childImageSharp.fixed : {}}
                                    alt={item.title} key={i}
                                    /></a>
                                ))}
                  </div>
                  </div>
              </Box>
  </Layout>
);

About.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About;


export const query = graphql`
  query AboutQuery {
    aboutJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      gallery {
              title
              copy
              image {
                childImageSharp {
                  fixed(height: 80, quality: 90) {
                    ...GatsbyImageSharpFixed

                  }
                }
              }
            }
    },

    iconsJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fixed(height: 50) {
              ...GatsbyImageSharpFixed

            }
          }
        }
      }
    }
    contactJson {
            title
            content {
              childMarkdownRemark {
                html
              }
           }
          gallery {
                        title
                        copy
                        image {
                          childImageSharp {
                            fixed(height: 40, quality: 90) {
                              ...GatsbyImageSharpFixed

                            }
                          }
                        }
                      }
          }
  }

`;
