import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import Gallery from 'components/gallery_s';
import IOExample from 'components/io-example';
import Modal from 'containers/modal';
import { graphql } from 'gatsby';
import Menu from 'components/menu';
import Head from 'components/head';
import Img from 'gatsby-image';

const Offering = ({ data }) => (
   <Layout>
        <Head pageTitle={data.offeringJson.title} />
        <Box>
                    <div className="title">
                    Services
                    </div>
                </Box>
        <Gallery items={data.offeringJson.gallery} />
       <Box>
               <div className="wrapper">
               <div className="contact">
               {data.contactJson.gallery.map((item, i) => (
                                 <a href={item.copy} key={i}>
                                 <Img
                                 style={{opacity: 0.8,
                                         borderRadius: '80%',
                                         filter: 'grayscale(10%)',
                                         border: '4px solid #C0C0C0',
                                         margin: '1rem'
                                 }}
                                 fixed={item.image ? item.image.childImageSharp.fixed : {}}
                                 alt={item.title} key={i}
                                 /></a>
                             ))}
               </div>
               </div>
           </Box>
   </Layout>
);

Offering.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Offering;

export const query = graphql`
  query OfferingQuery {
    offeringJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fixed(height: 48, width:48) {
              ...GatsbyImageSharpFixed

            }
          }
        }
      }
    }
    contactJson {
            title
            content {
              childMarkdownRemark {
                html
              }
           }
          gallery {
                        title
                        copy
                        image {
                          childImageSharp {
                            fixed(height: 40, quality: 90) {
                              ...GatsbyImageSharpFixed

                            }
                          }
                        }
                      }
          }
  }
`;
