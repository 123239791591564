import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
   display: grid;
     align-items: center ;
     text-align:center;
    padding:0rem 0rem;
    margin: 0rem;

  ${MEDIA.TABLET`
    display: block;
  `};




`;
