import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import Gallery from 'components/gallery';
import IOExample from 'components/io-example';
import Modal from 'containers/modal';
import { graphql } from 'gatsby';
import Menu from 'components/menu';
import Img from 'gatsby-image';
import Head from 'components/head';

const References = ({ data }) => (
   <Layout>
        <Head pageTitle={data.referencesJson.title} />
        <Box>
            <div className="title">
            List of projects
            </div>
        </Box>
        <Box>
        <div className="customers">
                   {data.referencesJson.gallery2.map((item, i) => (
                                      <Img
                                      fixed={item.image ? item.image.childImageSharp.fixed : {}}
                                      alt={item.title}
                                      key={i}
                                      style={{opacity: 1, filter: 'grayscale(0%)',margin: '0rem 1rem'}}
                                      />

                           ))}
        </div>
                   </Box>

        <Gallery items={data.referencesJson.gallery} />

       <Box>
                      <div className="wrapper">
                      <div className="contact">
                      {data.contactJson.gallery.map((item, i) => (
                                        <a href={item.copy} key={i}>
                                        <Img
                                        style={{opacity: 0.8,
                                                borderRadius: '80%',
                                                filter: 'grayscale(10%)',
                                                border: '4px solid #C0C0C0',
                                                margin: '1rem'
                                        }}
                                        fixed={item.image ? item.image.childImageSharp.fixed : {}}
                                        alt={item.title} key={i}
                                        /></a>
                                    ))}
                      </div>
                      </div>
                  </Box>

   </Layout>
);

References.propTypes = {
  data: PropTypes.object.isRequired,
};

export default References;

export const query = graphql`
  query ReferencesQuery {
    referencesJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        copy2
        image {
          childImageSharp {
            fluid(maxHeight: 200, maxWidth:500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      gallery2 {
              title
              copy
             copy2
            image {
              childImageSharp {
                fixed(width:60, quality:50) {
                  ...GatsbyImageSharpFixed

                }
              }
            }
            }
    }
    contactJson {
            title
            content {
              childMarkdownRemark {
                html
              }
           }
          gallery {
                        title
                        copy
                        image {
                          childImageSharp {
                            fixed(height: 40, quality: 90) {
                              ...GatsbyImageSharpFixed

                            }
                          }
                        }
                      }
          }
  }
`;
/*
<Box>
        {data.referencesJson.gallery2.map((item, i) => (
            <Img fixed={item.image ? item.image.childImageSharp.fixed : {}} alt={item.title} key={i} />
        ))}

        </Box>
        */